import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
import router from '@/router'
import errorHandler from '@/helpers/errorHandler'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    subjects: {},
    currentSubject: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_SUBJECTS_STUDENT({ commit, dispatch }, { idMurid, idKelas }) {
      try {
        commit('SET_STATE', {
          isLoading: true,
        }, { root: true })
        const { data: { data } } = await axios(`${config.apiUrl}/api/kelas/class-by-subject`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_murid: idMurid,
            id_kelas: idKelas,
          },
        })
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        commit('SET_STATE', {
          subjects: data,
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        errorHandler(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_SUMMARY_ASSIGNMENT({ commit, dispatch }, { idMapel, idMurid }) {
      try {
        commit('SET_STATE', { isLoading: true }, { root: true })
        const { data } = await axios(`${config.apiUrl}/api/kelas/by-subject/assignment-summary`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_murid: idMurid,
            id_mata_pelajaran: idMapel,
          },
        })
        commit('SET_STATE', { isLoading: false }, { root: true })
        const summary = data.data

        return new Promise((resolve, reject) => {
          resolve(summary)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async FETCH_SUMMARY_ASSIGNMENT_ALL_SUBJECT({ commit, dispatch }, { idMurid }) {
      try {
        // commit('SET_STATE', { isLoading: true }, { root: true })
        const { data } = await axios(`${config.apiUrl}/api/kelas/assignment-summary`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_murid: idMurid,
          },
        })
        // commit('SET_STATE', { isLoading: false }, { root: true })
        const summary = data.data

        return new Promise((resolve, reject) => {
          resolve(summary)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    FETCH_CARD_AIP({ commit, dispatch, state }, { idMapel, idMurid, idKelas }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/sesi/class-by-subject/detail/aip-card`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_mata_pelajaran: idMapel,
          id_murid: idMurid,
          id_kelas: idKelas,
        },
      })
        .then(({ data: { data } }) => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          // console.log(data, 'ini aip card')
          // const sessions = data.aipCard.map(sesi => {
          //   return {
          //     namaMapel: sesi.mata_pelajaran.nama,

          //   }
          // })
          commit('SET_STATE', {
            currentSubject: {
              ...state.currentSubject,
              AIPCard: data.aipCard,
              totalWeeks: data.totalWeek,
            },
          })
        })

        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          if (err.response.data.status === 'NOT_AUTHORIZED') {
            router.push({ name: '404' })
          }
          errorHandler(err)
          console.log(err.response)
        })
    },
    async FETCH_LIST_AIP({ commit, dispatch, state }, { idMapel, idMurid, idKelas, page }) {
      try {
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        const { data: { data, totalSesi } } = await axios(`${config.apiUrl}/api/sesi/class-by-subject/detail/aip-list`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_murid: idMurid,
            id_kelas: idKelas,
            // page,
          },
        })
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        commit('SET_STATE', {
          currentSubject: {
            ...state.currentSubject,
            AIPList: data,
          },
        })

        return new Promise((resolve) => resolve({ data, totalSesi }))
      } catch (err) {
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        errorHandler(err)
        console.log(err.response)
      }
    },
    async FETCH_STUDENT_OVERALL({ commit, dispatch, state }, { idMapel, idMurid, idKelas }) {
      try {
        commit('SET_STATE', {
          isLoading: true,
        }, { root: true })
        const { data: { data } } = await axios(`${config.apiUrl}/api/slot/student-overall-by-subject`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_murid: idMurid,
            id_kelas: idKelas,
          },
        })
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        commit('SET_STATE', {
          currentSubject: {
            ...state.currentSubject,
            studentOverall: data,
          },
        })

        return new Promise((resolve) => resolve(data))
      } catch (err) {
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        errorHandler(err)
        console.log(err.response)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_STUDENT_OVERALL_SUBJECT({ commit, dispatch, state }, { idMapel, idMurid, idKelas }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/slot/subject-detail-overall`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_murid: idMurid,
            id_kelas: idKelas,
          },
        })
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        errorHandler(err)
        console.log(err.response)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_SUBJECTS(
      { commit, dispatch, state },
    ) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/mata_pelajaran`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data)
          }, 500)
        })
      } catch (error) {
        errorHandler(error)
        console.log('error', error)
      }
    },
    async FETCH_TEACHER_SUBJECTS(
      { commit, dispatch, state },
    ) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/guru/pengampu/mata-pelajaran-dan-tingkat`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data)
          }, 500)
        })
      } catch (error) {
        errorHandler(error)
        console.log('error', error)
      }
    },
  },
}
