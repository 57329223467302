import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_ALL_BANK_SOAL(
      { commit },
      { tipe, search, order, sortBy, limit, page, idMapel, idLevel },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data, total },
        } = await axios(`${config.apiUrl}/api/bank-soal`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            tipe,
            search,
            order,
            sortBy,
            limit,
            page,
            id_mata_pelajaran: idMapel,
            id_level: idLevel,
          },
        })
        return new Promise((resolve, reject) => {
          resolve({ data, total })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_QUESTION_BANK_BY_ID(
      { commit },
      { id },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/bank-soal/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async DELETE_QUESTION_BANK({ commit, dispatch, state }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/bank-soal/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async PUT_QUESTION_BANK(_, { id, newData = {} }) {
      try {
        await axios(`${config.apiUrl}/api/bank-soal/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async POST_QUESTION_BANK(_, { newData = {} }) {
      try {
        await axios(`${config.apiUrl}/api/bank-soal`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
}
