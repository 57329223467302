import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import router from '@/router'
import config from '@/config'
import errorHandler from '@/helpers/errorHandler'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    slots: [],
    sesis: [],
    kelasSesis: [],
    currentSlot: {},
    currentSlotStatus: '',
    currentDay: new Date(),
    semesterFirstDay: '',
    // semesterFirstDay: moment('4 January 2021', 'DD MMMM YYYY'),
    // semesterFirstDay: '04 January 2021',
    biweeklyTopic: [],
    sesiGradings: {},
    assignmentInProgress: {
      count: [],
      rows: [],
    },
    modelTipe: 'A',
    allAssignmentInProgress: [],
    menuSlots: [],
    overviewSlot: {},
    overviewAssignment: {},
    overviewAips: [],
    aapAips: [],
    overviewClassAipPresence: {},
    listAipPresencesMurids: [],
    assignmentColumns: [],
    dataOfTablePlanning: [],
    dataOfTableAssignmentGrading: [],
    dataOfTableAP: [],
    semesterProgress: {},
    idNextSession: null,
    fileMaterials: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_TAHUN_AJARAN_ACTIVE({ commit, dispatch, state }) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/tahun_ajaran/active`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        console.log(data.data)
        const tanggalMoment = moment(data.data.tanggal_awal)
        // state.semesterFirstDay = new Date(tanggalMoment)
        // console.log(state.semesterFirstDay)
        return new Promise((resolve, reject) => {
          resolve(tanggalMoment)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async FETCH_SLOT_TEACHER({ commit, dispatch, state }, { idWeek }) {
      try {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/slot/schedule`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_week: idWeek,
          },
        })
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        commit('SET_STATE', {
          slots: data,
        })
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        errorHandler(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_WEEKS({ commit, dispatch, state }) {
      try {
        const {
          data: { data, current, tahun_ajaran: tahunAjaran },
        } = await axios(`${config.apiUrl}/api/week/by-tahun-ajaran`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) =>
          resolve({ data, current: current || {}, tahunAjaran }),
        )
        // console.log('WEEKS', data)
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_SLOT_STUDENT({ commit, dispatch, state }, { idWeek }) {
      try {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/slot/studentSchedule`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_week: idWeek,
          },
        })
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        commit('SET_STATE', {
          slots: data,
        })
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        errorHandler(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_CURRENT_SLOT({ commit, dispatch, state }, { idSession }) {
      try {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )
        // let zoomSetting = false
        const {
          data: { data, idNextSession, model },
        } = await axios(`${config.apiUrl}/api/sesi/${idSession}`, {
          // await axios(`http://localhost:3000/api/sesi/${idSession}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        // console.log(data)
        commit('SET_STATE', {
          currentSlot: {},
        })
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        commit('SET_STATE', {
          currentSlot: data,
          idNextSession,
        })
        // zoomSetting = data.zoom_setting

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              session: data,
              idNextSession,
              model,
            })
          }, 1000)
        })
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        errorHandler(err)
        console.log(err.response)
        return new Promise((resolve, reject) => reject(err))
      }
      // console.log(idSession, 'ini di actions store')
    },
    // ! DASHBOARD GURU TODAY CLASS
    FETCH_TODAY_CLASS({ commit, dispatch }, { idGuru }) {
      commit(
        'SET_STATE',
        {
          isLoading: true,
        },
        { root: true },
      )
      axios(`${config.apiUrl}/api/dashboard-guru/kelas`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          // console.log('masuk')
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          // console.log(data.data)
          commit('SET_STATE', {
            sesis: data.data,
          })
        })
        .catch((err) => {
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          errorHandler(err)
          console.log(err)
        })
    },
    // ! DASHBOARD MURID TODAY CLASS
    FETCH_TODAY_CLASS_MURID({ commit, dispatch }, { idKelas }) {
      axios(`${config.apiUrl}/api/dashboard-murid/kelas`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_kelas: idKelas,
        },
      })
        .then(({ data }) => {
          // console.log(data.data.rows)
          commit('SET_STATE', {
            kelasSesis: data.data,
          })
        })
        .catch((err) => {
          errorHandler(err)
          console.log(err)
        })
    },
    // ! BIWEEKLY PLANNING GURU
    FETCH_BIWEEKLY_TOPIC({ commit, dispatch, state }, { idGuru }) {
      commit(
        'SET_STATE',
        {
          isLoading: true,
        },
        { root: true },
      )
      axios(`${config.apiUrl}/api/topik/planning`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data: { data } }) => {
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          commit('SET_STATE', {
            biweeklyTopic: data,
          })
        })
        .catch((err) => {
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          errorHandler(err)
          console.log(err)
        })
    },
    // ! DASHBOARD GURU AIP
    FETCH_ASSIGNMENT_IN_PROGRESS({ commit, dispatch, state }, { idGuru }) {
      commit(
        'SET_STATE',
        {
          isLoading: true,
        },
        { root: true },
      )
      axios(`${config.apiUrl}/api/dashboard-guru/aip`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          commit('SET_STATE', {
            assignmentInProgress: data.data,
          })
        })
        .catch((err) => {
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          errorHandler(err)
          console.log(err)
        })
    },
    // ! DASHBOARD GURU ALLAIP
    FETCH_ALL_ASSIGNMENT_IN_PROGRESS({ commit, dispatch, state }, { idGuru }) {
      commit(
        'SET_STATE',
        {
          isLoading: true,
        },
        { root: true },
      )
      axios(`${config.apiUrl}/api/dashboard-guru/allaip`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          // console.log(data.data.data1[0])
          commit('SET_STATE', {
            allAssignmentInProgress: data.data,
          })
        })
        .catch((err) => {
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          errorHandler(err)
          console.log(err)
        })
    },
    // ! DASHBOARD MURID SEMESTER PROGRESS
    FETCH_SEMESTER_PROGRESS({ commit, dispatch, state }) {
      axios(`${config.apiUrl}/api/dashboard-murid/semester-progress`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            semesterProgress: data.data,
          })
        })
        .catch((err) => {
          errorHandler(err)
          console.log(err)
        })
    },
    // ! ASSIGNMENT GRADING GURU
    async FETCH_ASSIGNMENT_GRADING({ commit, dispatch, state }, { idSesi }) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/slot/assignmentGrading`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_sesi: idSesi,
            },
          },
        )

        const slot = data
        let no = 0
        const tipeAssignment = slot.dataAG.sesis[0].tipe_assignment
        let pgSoals = ''
        let path = ''
        let answer = ''
        const dataTable = slot.dataAG.sesis[0].attendances.map((row) => {
          no++
          const submitTime =
            row.submit_time === null
              ? 'Waiting'
              : moment(row.submit_time).format('DD MMM YYYY, HH:mm')
          if (tipeAssignment === 'File Upload') {
            path = row.tugas_filepath
            answer = row.tugas_filename
          } else if (tipeAssignment === 'Essay') {
            path = ''
            answer = row.essay_answer
          } else if (tipeAssignment === 'Multiple Choice') {
            path = ''
            answer = JSON.parse(row.jawaban_murid)
            pgSoals = slot.dataAG.sesis[0].pg_soals
          }
          return {
            key: row.id_murid,
            No: no,
            idAttendance: row.id,
            idMurid: row.id_murid,
            Name: row.murid.nama,
            SubmitStatus: submitTime,
            TipeAssignment: tipeAssignment,
            AssignmentPath: path,
            AssignmentDetail: pgSoals,
            AssignmentAnswer: answer,
            Feedback: row.feedback,
            Grade: row.tugas_nilai,
            Notes: row.notes,
          }
        })

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ slot, dataTable })
          })
        })
      } catch (err) {
        console.log(err)
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        errorHandler(err)
        console.log(err.response)
      }
    },
    // ! PUT GRADING
    async PUT_GRADING(
      { commit, dispatch, rootState },
      { data, idSesi, idKd, idMataPelajaran },
    ) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/slot/assignmentGrading`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            id_sesi: idSesi,
            id_kd: idKd,
            id_mata_pelajaran: idMataPelajaran,
            data,
          },
        })
        isSuccess = true

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(isSuccess)
          }, 1000)
        })
      } catch (err) {
        isSuccess = false
        console.log(err)
        errorHandler(err)
      }
      // console.log('masuk', payload.data)
    },
    // ! FILE DETAIL SESI
    FETCH_FILE_MATERIAL({ commit, dispatch, state }, { idTopik }) {
      axios(`${config.apiUrl}/api/file/file-topik`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_topik: idTopik,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            fileMaterials: data.data,
          })
        })
        .catch((err) => {
          console.log(err)
          errorHandler(err)
        })
    },
    // ! DELETE FILE MATERIAL
    DELETE_FILE_MATERIAL({ commit, dispatch, rootState }, payload) {
      // console.log('masuk', localStorage.token)
      axios
        .delete(`${config.apiUrl}/api/file/file-topik/${payload.id}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((res) => {
          const idTopik = payload.id_topik
          dispatch('FETCH_FILE_MATERIAL', { idTopik })
        })
        .catch((err) => {
          console.log(err)
          errorHandler(err)
        })
    },
    // ! DELETE FILE ASSIGNMENT MURID
    DELETE_FILE_ASSIGNMENT_MURID({ commit, dispatch, rootState }, payload) {
      // console.log('masuk', localStorage.token)
      axios(`${config.apiUrl}/api/attendance/assignment-murid/${payload.id}`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          const idMurid = payload.id_murid
          const idSesi = payload.id_sesi
          dispatch('FETCH_USER_ASSIGNMENT_BY_ID_SESSION', { idMurid, idSesi })
        })
        .catch((err) => {
          console.log(err)
          errorHandler(err)
        })
    },
    // ! PUT DETAIL SESI
    async PUT_DETAIL_SESI({ commit, dispatch, rootState }, payload) {
      try {
        await axios(`${config.apiUrl}/api/sesi/detail-sesi`, {
          // await axios('http://localhost:3000/api/sesi/detail-sesi', {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            data: payload.data,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        errorHandler(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    // ! MENU SLOT PLANNING & ASSIGNMENT CENTER GURU
    async FETCH_MENU_SLOT_TEACHER({ commit, dispatch, state }) {
      try {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/slot/kelastingkat`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        errorHandler(err)
        return new Promise((resolve, reject) => {
          commit(
            'SET_STATE',
            {
              isLoading: false,
            },
            { root: true },
          )
          reject(err)
        })
      }
    },
    // ! OVERVIEW SLOT PLANNING GURU
    async FETCH_OVERVIEW_SLOT_TEACHER(
      { commit, dispatch, state },
      { idGuru, namaMapel, tingkat },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/sesi/planning/overview`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_guru: idGuru,
              nama_mapel: namaMapel,
              tingkat: tingkat,
            },
          },
        )
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        let no = 0
        let keyC = 0
        const dataTable = data.data.data.map((row) => {
          const updatedAt = moment(row.updatedAt).format('DD MMM YYYY, HH:mm')
          let duration = moment('00:00', 'HH:mm').format('HH:mm')
          const date = []
          row.sesis.forEach((sesiRow) => {
            duration = moment(duration, 'HH:mm')
              .add(sesiRow.slot.model_jadwal.durasi, 'minutes')
              .format('HH:mm')
            date.push(moment(sesiRow.tanggal_sesi))
          })
          const minDate = moment.min(date)
          const maxDate = moment.max(date)
          no++
          const dataChildTable = row.sesis.map((rowChildTable) => {
            keyC++
            const tglSesi = moment(rowChildTable.tanggal_sesi).format(
              'DD MMMM YYYY',
            )
            let statusLive = ''
            if (rowChildTable.zoom_setting) {
              statusLive = 'Live'
            } else {
              statusLive = ''
            }
            return {
              key: 'C' + keyC,
              Class:
                rowChildTable.slot.kela.tingkat +
                '-' +
                rowChildTable.slot.kela.simbol,
              Date: tglSesi + ', ' + rowChildTable.slot.model_jadwal.jam_mulai,
              // Status: 'Class Done',
              Duration: '00:' + rowChildTable.slot.model_jadwal.durasi,
              Live: statusLive,
              Assign: rowChildTable.tipe_assignment,
            }
          })
          return {
            key: 'D' + no,
            // No: no,
            Class:
              row.sesis.length + (row.sesis.length > 1 ? ' Classes' : ' Class'),
            Date:
              minDate === maxDate
                ? minDate.locale('id').format('DD MMM YYYY')
                : `${minDate.locale('id').format('DD MMM YYYY')} - ${maxDate
                    .locale('id')
                    .format('DD MMM YYYY')}`,
            Topic: row.nama,
            Duration: duration,
            Updated: updatedAt,
            Live: '',
            Assign: '',
            children: dataChildTable,
          }
        })
        // console.log(dataTable)
        commit('SET_STATE', {
          overviewSlot: data.data,
          dataOfTablePlanning: dataTable,
          dataOfTableAssignmentGrading: dataTable,
        })
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
      // commit('SET_STATE', {
      //   isLoading: true,
      // }, { root: true })
    },
    // ! OVERVIEW ASSIGNMENT CENTER GURU
    async FETCH_OVERVIEW_ASSIGNMENT_TEACHER(
      { commit, dispatch, state },
      { idMapel, idLevel },
    ) {
      try {
        const {
          data: { overview, data },
        } = await axios(`${config.apiUrl}/api/sesi/assignment-overview`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_level: idLevel,
          },
        })
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )

        console.log(data, 'tes')
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ overview, data })
          }, 1000)
        })
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        console.log(err.response)
        errorHandler(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    // ! OVERVIEW AIP ASSIGNMENT CENTER GURU
    async FETCH_OVERVIEW_AIP_TEACHER(
      { commit, dispatch, state },
      { idMapel, idLevel },
    ) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/sesi/aip`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_level: idLevel,
          },
        })

        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )

        errorHandler(err)

        return new Promise((resolve, reject) => reject(err))
      }
    },
    // ! OVERVIEW AIP DETAIL AAP GURU
    async FETCH_AIP_AAP_TEACHER(
      { commit, dispatch, state },
      { idMataPelajaran, idKelas },
    ) {
      try {
        const {
          data: { data, total_student: totalStudent },
        } = await axios(`${config.apiUrl}/api/sesi/aap-aip`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMataPelajaran,
            id_kelas: idKelas,
          },
        })
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )

        return new Promise((resolve, reject) => {
          resolve({ data, totalStudent })
        })
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        errorHandler(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    // ! OVERVIEW CLASS DETAIL AAP GURU
    async FETCH_OVERVIEW_CLASS_AAP_TEACHER(
      { commit, dispatch, state },
      { idMataPelajaran, idKelas },
    ) {
      try {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )
        const {
          data: {
            data: { dataTable, overview, sessionCol },
          },
        } = await axios(`${config.apiUrl}/api/sesi/aap-class`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMataPelajaran,
            id_kelas: idKelas,
          },
        })

        // let no = 0
        // const aColumns = []
        // let noSesi = 0
        // data.assG[0].forEach((rows) => {
        //   rows.sesis.forEach((rowSesi) => {
        //     if (rowSesi.tipe_assignment !== null) {
        //       noSesi++
        //       aColumns.push({
        //         key: aColumns.length,
        //         title: '#' + noSesi,
        //         dataIndex: 'A' + rowSesi.id,
        //         align: 'center',
        //         // width: 10,
        //       })
        //     }
        //   })
        // })
        // const dataTable =
        //   data.data_nama_grade[0].kela.kelas_tahun_ajarans.map((row) => {
        //     no++
        //     let cp
        //     let avgGrade
        //     data.cp_avg.map((rowCp) => {
        //       if (rowCp[0].sesis[0].attendances[0].id_murid === row.id) {
        //         cp = rowCp[0].sesis[0].attendances[0].class_presences * 100
        //         avgGrade = rowCp[0].sesis[0].attendances[0].average_grade
        //           ? rowCp[0].sesis[0].attendances[0].average_grade
        //           : '0.00'
        //       }
        //     })
        //     const coba = {}
        //     data.assG.forEach((rowAsg) => {
        //       // let index = 0
        //       rowAsg.forEach((rows) => {
        //         rows.sesis.forEach((rowS) => {
        //           if (rowS.tipe_assignment !== null) {
        //             if (rowS.attendances[0].id_murid === row.id) {
        //               // index++
        //               coba[`A${rowS.id}`] =
        //                 rowS.attendances[0].tugas_nilai || '-'
        //               // console.log(`Column A${index} | ID MURID = ${rowS.attendances[0].id_murid} | SESI = ${rowS.id} | NILAI = ${rowS.attendances[0].tugas_nilai}`)
        //             }
        //           }
        //         })
        //       })
        //     })
        //     // console.log(coba)
        //     // console.log(aColumns)
        //     return {
        //       key: row.murid.id,
        //       No: no,
        //       Name: row.murid.nama,
        //       ClassPresences: parseFloat(cp).toFixed(2),
        //       AverageGrade: parseFloat(avgGrade).toFixed(2),
        //       ...coba,
        //     }
        //   })
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )

        return new Promise((resolve, reject) => {
          resolve({
            overviewClassAipPresence: overview,
            dataTable,
            sessionCol,
          })
        })
      } catch (err) {
        errorHandler(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },

    async FETCH_USER_ASSIGNMENT_BY_ID_SESSION({ commit }, { idMurid, idSesi }) {
      let dataAssignment
      await axios(`${config.apiUrl}/api/attendance/assignment-murid`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
          id_sesi: idSesi,
        },
      })
        .then(({ data }) => {
          dataAssignment = data
        })
        .catch((err) => {
          errorHandler(err)
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(dataAssignment)
        }, 500)
      })
    },
    async UPDATE_USER_ESSAY_ASSIGNMENT_BY_ID_SESSION(
      { commit, dispatch },
      { idMurid, idSesi, essayAnswer },
    ) {
      try {
        await axios(`${config.apiUrl}/api/attendance/assignment-murid`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            id_murid: idMurid,
            id_sesi: idSesi,
            essay_answer: essayAnswer,
          },
        })

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(essayAnswer)
          }, 500)
        })
      } catch (err) {
        errorHandler(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_CLASS_ASSIGNMENT_BY_MAPEL_TEACHER(
      { commit, dispatch },
      { idMapel, idWeek, idLevel },
    ) {
      // const { data: { data } } = await axios('http://localhost:3000/api/kelas/class-by-mapel-session', {
      const {
        data: { data },
      } = await axios(`${config.apiUrl}/api/kelas/class-by-mapel-session`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_mata_pelajaran: idMapel,
          id_week: idWeek,
          id_level: idLevel,
        },
      })

      return new Promise((resolve, reject) => {
        resolve(data)
      })
    },
    async FETCH_MODEL_ACTIVE_SCHEDULE({ commit, dispatch, state }) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/model/active`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
        errorHandler(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_CURRENT_WEEK({ commit, dispatch, state }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/week/current`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        const tanggalMoment = moment(data.data.tanggal_awal)
        // state.semesterFirstDay = new Date(tanggalMoment)
        // console.log(state.semesterFirstDay)
        return new Promise((resolve, reject) => {
          resolve(tanggalMoment)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async FETCH_HEADER_ASSIGNMENT_GRADING(
      { commit, dispatch, state },
      { idSesi },
    ) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/slot/header-assignmentGrading`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_sesi: idSesi,
          },
        })
        console.log(data)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async FETCH_DETAIL_ASSIGNMENT_GRADING(
      { commit, dispatch, state },
      { idSesi },
    ) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/slot/assignmentGrading`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_sesi: idSesi,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
  },
}
