import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import axios from 'axios'
import config from '@/config'
import { getBrowser, getOS } from '../../helpers/general'
import $ from 'jquery'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    user: {},
    role: '',
    authorized: false, // false is default value
    isLoading: false,
    isWrong: false,
    panggilan: '',
    modelJadwal: {
      tipe: 'A',
      jam_mulai: '08:00',
      durasi: 25,
      isMurajaah1: 1,
      isMurajaah2: 0,
      murajaah1dur: 25,
      murajaah2dur: 0,
      break1dur: 15,
      break2dur: 0,
    },
    tokenEmail: '',
    isPrincipal: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async GENERATE_NEW_PASSWORD({ commit, state }, payload) {
      let status = false
      try {
        const { password } = payload
        const { tokenEmail } = state
        // console.log(tokenEmail, password)
        const { data } = await axios(
          `${config.apiUrl}/api/account/reset-password`,
          {
            method: 'put',
            data: {
              tokenEmail,
              password,
            },
          },
        )

        status = true

        const message = data.message
        return new Promise((resolve, reject) => {
          resolve({ status, message })
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve({ status })
        })
      }
    },
    async GENERATE_NEW_PASSWORD_PARENT({ commit, state }, payload) {
      let status = false
      try {
        const { password } = payload
        const { tokenEmail } = state
        // console.log(tokenEmail, password)
        const { data } = await axios(
          `${config.apiUrl}/api/account/reset-password-parent`,
          {
            method: 'put',
            data: {
              tokenEmail,
              password,
            },
          },
        )

        status = true

        const message = data.message
        return new Promise((resolve, reject) => {
          resolve({ status, message })
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve({ status })
        })
      }
    },
    async GENERATE_LINK_FORGOT_PASSWORD({ commit }, payload) {
      let status = false
      try {
        const { email } = payload
        // console.log(email)
        const { data } = await axios(
          `${config.apiUrl}/api/account/request-forgot-password`,
          {
            method: 'post',
            data: {
              email,
            },
          },
        )
        status = true
        const message = data.message
        return new Promise((resolve, reject) => {
          resolve({ status, message })
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve({ status })
        })
      }
    },
    async LOGIN({ commit, dispatch, rootState }, payload) {
      // console.log('masuk', payload)
      try {
        const { username, password, token } = payload
        let nama = ''
        let role = ''
        let isError = false
        let status = ''
        const os = getOS()
        const browser = getBrowser()
        const { ip } = await $.getJSON('https://api.ipify.org?format=json')
        const { data } = await axios(`${config.apiUrl}/api/login`, {
          method: 'post',
          data: {
            username,
            password,
            deviceInfo: {
              os,
              browser,
              ip,
            },
          },
        })
        // console.log(data)
        // console.log(data.message)
        if (data.message === 'INVALID_PASSWORD') {
          isError = true
          status = `Invalid password count: ${data.count} times`
        } else if (data.message === 'NIS_NOT_FOUND') {
          isError = true
          status = 'NIS/NIK not found. Please try again.'
        } else if (data.token) {
          // console.log(data)
          let user
          localStorage.setItem('authorized', 'true')
          localStorage.setItem('token', data.token)
          localStorage.setItem('isLive', false)
          if (data.guru) {
            nama = data.guru.nama
            // console.log(data.guru)
            if (data.guru.jenis_kelamin === 'Pria') {
              commit('SET_STATE', {
                panggilan: 'Pak',
              })
            } else if (data.guru.jenis_kelamin === 'Wanita') {
              commit('SET_STATE', {
                panggilan: 'Bu',
              })
            } else {
              commit('SET_STATE', {
                panggilan: '',
              })
            }
            console.log(data.guru.role)
            role = JSON.parse(data.guru.role)
            if (data.guru.isPrincipal) {
              role.push('kepalaSekolah')
            }
            localStorage.setItem('role', JSON.stringify(role))
            user = data.guru
            router.push({ name: 'Dashboard Teacher' })
          } else if (data.murid) {
            nama = data.murid.nama
            role = ['murid']
            user = data.murid
            user.role = ['murid']
            localStorage.setItem('role', JSON.stringify(role))
            localStorage.setItem('isOnExam', false)
            if (data.murid.isPpdb) {
              localStorage.setItem('isPpdb', true)
            }
            if (token) {
              const { cbtType, idCbt } = token
              // console.log(cbtType, idCbt)
              localStorage.setItem('cbtType', cbtType)
              commit(
                'SET_STATE',
                {
                  cbtType,
                },
                { root: true },
              )
              if (data.murid.isPpdb) {
                router.push({
                  name: 'PPDB Student',
                })
              } else {
                router.push({
                  name: 'Cbt Start Exam Student',
                  params: { id: idCbt },
                })
              }
              // commit('menu/SET_STATE', {
              //   currentMenu: ['Cbt Student'],
              // })
            } else {
              router.push({ name: 'Dashboard Student' })
            }
          } else if (data.admin) {
            // nama = data.admin.username
            // role = ['admin']
            // user = data.admin
            // user.role = ['admin']
            // localStorage.setItem('role', JSON.stringify(role))
            // router.push({ name: 'Master Admin' })
            nama = data.admin.username
            role = [data.admin.role]
            user = data.admin
            user.role = [data.admin.role]
            localStorage.setItem('role', JSON.stringify(role))
            if (role.includes('admin')) {
              router.push({ name: 'Dashboard Admin' })
            }
            // else if (role.includes('HR')) {
            //   router.push({ name: 'Kehadiran Admin HR' })
            // } else if (role.includes('keuangan')) {
            //   router.push({ name: 'Keuangan Admin Keuangan' })
            // }
          }
          // localStorage.setItem('id_tahun_ajaran', JSON.stringify(data.idTahunAjaran))
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('version', process.env.VUE_APP_VERSION)
          dispatch('master/FETCH_INSTITUSI', null, { root: true })
          // console.log(user)
          // console.log(role)
          commit('SET_STATE', {
            loading: true,
            user,
            authorized: true,
            role,
          })
          commit(
            'SET_STATE',
            {
              ckEditor: {
                ...rootState.ckEditor,
                editorConfig: {
                  ...rootState.ckEditor.editorConfig,
                  simpleUpload: {
                    ...rootState.ckEditor.editorConfig.simpleUpload,
                    headers: {
                      'X-CSRF-TOKEN': 'CSRF-Token',
                      token: data.token,
                      api: config.apiUrl,
                    },
                  },
                },
              },
            },
            { root: true },
          )
        } else {
          isError = true
        }
        // console.log(isError)
        return new Promise((resolve, reject) => {
          resolve({
            isError,
            status,
            nama,
            role,
            message: data.message,
          })
        })
      } catch (err) {
        // console.log(err.response.statusText)
        return new Promise((resolve, reject) => {
          resolve({
            isError: true,
            status:
              err.response.statusText === 'Too Many Requests'
                ? 'Too Many Requests'
                : '',
          })
        })
      }
    },
    // LOAD_CURRENT_ACCOUNT({ commit, rootState }) {
    //   commit('SET_STATE', {
    //     loading: true,
    //   })

    //   const currentAccount = mapAuthProviders[rootState.settings.authProvider].currentAccount
    //   currentAccount().then(response => {
    //     if (response) {
    //       const { id, email, name, avatar, role } = response
    //       commit('SET_STATE', {
    //         id,
    //         name,
    //         email,
    //         avatar,
    //         role,
    //         authorized: true,
    //       })
    //     }
    //     commit('SET_STATE', {
    //       loading: false,
    //     })
    //   })
    // },
    LOGOUT({ commit, dispatch, rootState }) {
      localStorage.clear()
      localStorage.setItem('version', process.env.VUE_APP_VERSION)
      dispatch('master/FETCH_INSTITUSI', null, { root: true })
      router.push('/login')
    },
    GET_USER({ commit }, { user, role }) {
      commit('SET_STATE', {
        user,
        role,
      })
    },
    async GET_USER_BY_ID({ commit }, { payload }) {
      try {
        // console.log('payload', payload)
        const { data } = await axios(
          `${config.apiUrl}/api/account/${payload.id}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              ...payload,
            },
          },
        )

        const user = data.data

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(user)
          }, 500)
        })
      } catch (error) {
        console.log(error)
      }
    },
    CHANGE_PASSWORD({ commit, dispatch, rootState }, payload) {
      let message
      // console.log(payload.newData)
      axios(`${config.apiUrl}/api/login/reset`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          data: payload.newData,
        },
      })
        .then((res) => {
          message = res.data.message
          // console.log('Log DARI VUEX', message)
        })
        .catch((err) => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(message)
        }, 500)
      })
    },
    async VERIFY_EMAIL({ commit, dispatch, rootState }, { payload }) {
      try {
        await axios(
          `${config.apiUrl}/api/account/success-verify/${payload.id}`,
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
            data: {
              ...payload,
            },
          },
        )
        // console.log(data)
      } catch (error) {
        console.log(error)
      }
    },
    async VERIFY_EMAIL_PARENT({ commit, dispatch, rootState }, { payload }) {
      try {
        await axios(
          `${config.apiUrl}/api/account/parent/success-verify/${payload.id}`,
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
            data: {
              ...payload,
            },
          },
        )
        // console.log(data)
      } catch (error) {
        console.log(error)
      }
    },
    async SEND_EMAIL({ commit, dispatch, rootState }, { payload }) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/account/verify-status-email/${payload.id}`,
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
            data: {
              ...payload,
            },
          },
        )
        // console.log(data)
        return new Promise((resolve, reject) => {
          resolve(data.message)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async UPDATE_EMAIL({ commit, dispatch, state }, { dataIncome }) {
      try {
        // console.log(dataIncome)
        const { data } = await axios(
          `${config.apiUrl}/api/account/verify-email-update/${dataIncome.id}`,
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
            data: {
              ...dataIncome,
            },
          },
        )
        const local = JSON.parse(localStorage.user)
        if (data.message === 'EMAIL_SENT') {
          state.user.email = dataIncome.email
          local.email = dataIncome.email
          localStorage.token = data.newToken
          localStorage.user = JSON.stringify(local)
        }

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_TAHUN_AJARAN() {
      try {
        const {
          data: { data, activeYear },
        } = await axios(`${config.apiUrl}/api/tahun_ajaran`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        // console.log(data)
        return new Promise((resolve, reject) => {
          resolve({ data, activeYear })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_PROFILE_PICTURE() {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/file/foto-murid`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve) => resolve(data))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_GRADUATION_LETTER(_, { uuid }) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/public/skl-validation/${uuid}`, {
          method: 'get',
        })

        return new Promise((resolve) => resolve(data))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  getters: {
    user: (state) => state,
  },
}
