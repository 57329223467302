import { forceLogout } from './general'
import { notification } from 'ant-design-vue'
import router from '@/router'

export default (err) => {
  const status = err.response?.data?.status
  // console.log(err)
  // const status = 'TOKEN_EXPIRED'
  if (!status) return
  const currentRoute = router.currentRoute || {}
  switch (status) {
    case 'TOKEN_EXPIRED':
      if (currentRoute.name !== 'Login') {
        notification.error({
          message: 'TOKEN EXPIRED',
          // description: 'You logout since there are someone login on your account on another device',
          description:
            'You logout since your token is expired. Please re-login!',
        })
      }
      return forceLogout()
    case 'TOKEN_UNMATCH':
      if (currentRoute.name !== 'Login') {
        notification.error({
          message: 'ANOTHER LOGIN ATTEMPT DETECTED',
          description:
            'You logout since there are other device login on your account. Please re-login!',
        })
      }
      return forceLogout()
    default:
      return status
  }
}
