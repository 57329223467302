import router from '@/router'
import store from '@/store'

export const forceLogout = () => {
  localStorage.clear()
  localStorage.setItem('version', process.env.VUE_APP_VERSION)
  store.dispatch('master/FETCH_INSTITUSI', null, { root: true })
  router.push('/login')
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator?.userAgentData?.platform || window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  } else {
    os = 'Unknown'
  }

  return os
}
export const getBrowser = () => {
  let browser = null
  let version = null
  const userAgentString = navigator.userAgent
  if (userAgentString.indexOf('OP') > -1 && userAgentString.indexOf('Chrome') === -1) {
    browser = 'Safari'
    version = userAgentString.split('Safari/')[1]
  } else if (userAgentString.indexOf('Safari') > -1 && userAgentString.indexOf('Chrome') === -1) {
    browser = 'Safari'
    version = userAgentString.split('Safari/')[1]
  } else if (userAgentString.indexOf('Edg') > -1) {
    browser = 'Microsoft Edge'
    version = userAgentString.split('Edg/')[1]
  } else if (userAgentString.indexOf('Chrome') > -1) {
    browser = 'Google Chrome'
    version = userAgentString.split('Chrome/')[1]?.split(' ')[0]
  } else if (userAgentString.indexOf('MSIE') > -1 || (userAgentString.indexOf('rv:') > -1 && userAgentString.indexOf('like Gecko') > -1)) {
    browser = 'Internet Explorer'
    version = userAgentString.split('MSIE/')[1] || userAgentString.split('rv:')[1]
  } else if (userAgentString.indexOf('Firefox') > -1) {
    browser = 'Firefox'
    version = userAgentString.split('Firefox/')[1]
  } else {
    browser = 'Unknown'
  }

  const result = `${browser}${version ? ' ' + version : ''}`

  return result
}

export const capitalize = (string) => {
  let arr = string.split(' ')
  arr = arr.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())

  return arr.join(' ')
}

export const BKMarks = {
  1: 'Less Serious',
  3: 'Moderately Serious',
  5: 'Very Serious',
}
