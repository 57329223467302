import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
import errorHandler from '@/helpers/errorHandler'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    sesis: [],
    biweeklySessionPlanning: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_DASHBOARD_PLANNING({ commit, dispatch }, { idGuru }) {
      await axios(`${config.apiUrl}/api/dashboard-guru/planning`, {
        method: 'get',
        params: {
          id_guru: idGuru,
        },
        headers: {
          token: localStorage.token,
        },
      })
        .then(({ data: { result: data } }) => {
          const sesis = []
          data.sesis.forEach((sesi) => {
            let obj = {}
            for (let i = 0; i < data.unplanned_sesis.length; i++) {
              const unplannedSesi = data.unplanned_sesis[i]
              if (
                unplannedSesi['kela.tingkat'] === sesi['kela.tingkat'] &&
                unplannedSesi['mata_pelajaran.nama'] ===
                  sesi['mata_pelajaran.nama']
              ) {
                obj = {
                  ...sesi,
                  unplanned: unplannedSesi.count,
                  planned: sesi.total_sesi - unplannedSesi.count,
                  plannedRatio:
                    (sesi.total_sesi - unplannedSesi.count) / sesi.total_sesi,
                }
                sesis.push(obj)
                break
              } else if (
                i === data.unplanned_sesis.length - 1 &&
                unplannedSesi['kela.tingkat'] !== sesi['kela.tingkat'] &&
                unplannedSesi['mata_pelajaran.nama'] ===
                  sesi['mata_pelajaran.nama']
              ) {
                obj = {
                  ...sesi,
                  unplanned: 0,
                  planned: sesi.total_sesi,
                  plannedRatio: sesi.total_sesi / sesi.total_sesi,
                }
                sesis.push(obj)
              }
            }
          })
          commit('SET_STATE', {
            biweeklySessionPlanning: sesis,
          })
        })
        .catch((err) => {
          errorHandler(err)
          console.log(err)
        })
    },
    async UPDATE_ANSWER(
      { commit, dispatch },
      { tipeAssignment, answer, idMurid, idSesi },
    ) {
      let isSuccess = false
      // console.log(tipeAssignment)
      // console.log(answer)
      await axios(`${config.apiUrl}/api/attendance/update-answer`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          tipe_assignment: tipeAssignment,
          answer: answer,
        },
        params: {
          id_murid: idMurid,
          id_sesi: idSesi,
        },
      })
        .then((data) => {
          isSuccess = true
        })
        .catch((err) => {
          errorHandler(err)
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(isSuccess)
        }, 1000)
      })
    },
    async FETCH_TOPIK(
      { commit },
      { page, order, search, sortBy, idMataPelajaran, idLevel },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/topik`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
            id_mata_pelajaran: idMataPelajaran,
            id_level: idLevel,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async FETCH_KD(
      { commit },
      { page, order, search, sortBy, idMataPelajaran, idLevel },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/kd`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
            id_mata_pelajaran: idMataPelajaran,
            id_level: idLevel,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async FETCH_LIST_SESI(
      { commit },
      { page, order, search, sortBy, idMataPelajaran, idLevel, dates },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/sesi/list-sesi-between-date`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
            dates,
            id_mata_pelajaran: idMataPelajaran,
            id_level: idLevel,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async POST_SESI_TO_TOPIK({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataId, dataSesi } = payload
        await axios(`${config.apiUrl}/api/sesi/add-topik`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            id_topik: dataId.id,
            dataSesi,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        errorHandler(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async POST_SESI_TO_KD({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataId, dataSesi } = payload
        await axios(`${config.apiUrl}/api/sesi/add-kd`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            id_kd_master: dataId.id,
            dataSesi,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        errorHandler(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
  },
  getters: {
    user: (state) => state,
  },
}
