import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
// import { notification } from 'ant-design-vue'

import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    header: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_DATA_PREDICATE_ACTIVITIES() {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/template_report/activities`,
          {
            method: 'get',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
          },
        )
        // console.log(data.data)
        return new Promise((resolve, reject) => {
          resolve(data.data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_HOMEROOM_HEADER({ commit, dispatch, state }, { idGuru }) {
      let isSuccess = false
      try {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )
        const { data } = await axios(
          `${config.apiUrl}/api/guru/overall-header`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_guru: idGuru,
            },
          },
        )
        // console.log('data Header', data)
        commit('SET_STATE', {
          header: data.data,
        })
        isSuccess = true
      } catch (err) {
        const status = err.response.status
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        isSuccess = false
        if (status === 404) {
          router.push({ name: '404' })
        }
      }
      return new Promise((resolve, reject) => {
        resolve(isSuccess)
      })
    },
    async FETCH_DETAIL_GENERAL_REPORT({ commit, dispatch, state }, { id }) {
      try {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )
        const {
          data: { metadata, isMetadata, message, description, kumer },
        } = await axios(`${config.apiUrl}/api/general_report/metadata/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )

        return new Promise((resolve, reject) => {
          resolve({ metadata, isMetadata, message, description, kumer })
        })
      } catch (err) {
        const status = err.response.status
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        if (status === 404) {
          router.push({ name: '404' })
        }
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    FETCH_HOMEROOM_SELECT(
      { commit, dispatch, state },
      { idGuru, isCurriculum },
    ) {
      let selectMapel
      let selectWeek
      axios(`${config.apiUrl}/api/guru/mapel-week`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          isCurriculum,
        },
      })
        .then(({ data }) => {
          selectMapel = data.dataMapel
          selectWeek = data.dataWeek
        })
        .catch((err) => {
          console.log(err.response)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            selectMapel,
            selectWeek,
          })
        }, 1000)
      })
    },
    async FETCH_LIST_STUDENT_OF_CLASS(
      { commit, dispatch },
      { idKelas, type, page, order, search, sortBy },
    ) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/kelas_tahun_ajaran/list_student_general_report/${idKelas}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              type,
              page,
              order,
              search,
              sortBy,
            },
          },
        )

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async ADD_STUDENT_CLASS_SELECTED(
      { commit, dispatch },
      { idGuru, dataStudents, type },
    ) {
      let isSuccess = false
      try {
        await axios(
          `${config.apiUrl}/api/general_report/create_general_report_by_homeroom`,
          {
            method: 'post',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_guru: idGuru,
            },
            data: {
              listStudents: dataStudents,
              type,
            },
          },
        )
        isSuccess = true

        return new Promise((resolve) => {
          resolve(isSuccess)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(isSuccess)
        })
      }
    },
    async FETCH_HOMEROOM_TABLE_GRADE({ commit, dispatch, state }, { idGuru }) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/guru/overall-grade`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_guru: idGuru,
            },
          },
        )
        // console.log(data)
        const columnMapel = data.dataMapel
        const rowMurid = data.data

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              columnMapel,
              rowMurid,
            })
          }, 1000)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_HOMEROOM_TABLE_PRESENCES(
      { commit, dispatch, state },
      { idGuru, idMataPelajaran },
    ) {
      try {
        let columnTanggal
        let columnMapel
        let rowMurid
        if (idMataPelajaran === 'All') {
          idMataPelajaran = ''
        }
        const { data } = await axios(
          `${config.apiUrl}/api/guru/overall-presences`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_guru: idGuru,
              id_mata_pelajaran: idMataPelajaran,
            },
          },
        )
        // console.log('VUEX', data)
        if (data.dataTanggalSesi) {
          columnTanggal = data.dataTanggalSesi
          rowMurid = data.presencesMapel
        } else {
          columnMapel = data.dataMapelBaru
          rowMurid = data.dataBaru
        }

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              columnMapel,
              columnTanggal,
              rowMurid,
            })
          }, 5000)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_HOMEROOM_GENERAL_REPORT(
      { commit, dispatch, state },
      { idKelas, type },
    ) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/kelas_tahun_ajaran/list_student_general_report_table/${idKelas}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              type,
            },
          },
        )
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          resolve(err)
        })
      }
    },
    async FETCH_HOMEROOM_DAILY_ATTENDANCE(
      { commit, dispatch, state },
      { idKelas },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/absensi/list_attendance_kelas/${idKelas}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        const dataTable = data.data
        return new Promise((resolve, reject) => {
          resolve(dataTable)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_GENERAL_REPORT({ commit, dispatch, state }, { idGuru, data }) {
      let isSuccess = false
      let dataTable
      // console.log('payload', data)
      await axios(`${config.apiUrl}/api/general_report/update_all`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
        data: {
          data: data,
        },
      })
        .then((res) => {
          isSuccess = true
          dispatch('FETCH_HOMEROOM_GENERAL_REPORT').then((res) => {
            dataTable = res
          })
        })
        .catch((err) => {
          isSuccess = false
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            isSuccess,
            dataTable,
          })
        }, 2000)
      })
    },

    async FETCH_REPORT_REWARD_PUNISHMENT(
      { commit, dispatch, state },
      { idGuru, idGeneralReport },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/report_reward_punishment/${idGeneralReport}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_guru: idGuru,
            },
          },
        )
        const dataTable = data.data
        // console.log('FETCH_REPORT_REWARD_PUNISHMENT dataTable', dataTable)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(dataTable)
          }, 1000)
        })
      } catch (err) {
        console.log('err', err)
      }
    },
    async UPDATE_REPORT_REWARD_PUNISMENT(
      { commit, dispatch, state },
      { data },
    ) {
      let isSuccess = false
      let dataTable
      // console.log('data', data)
      await axios(`${config.apiUrl}/api/report_reward_punishment/update_all`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          data: data,
        },
      })
        .then((res) => {
          // console.log('res UPDATE_REPORT_REWARD_PUNISMENT', res)
          isSuccess = true
          dataTable = res.data.data
        })
        .catch((err) => {
          isSuccess = false
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            isSuccess,
            dataTable,
          })
        }, 2000)
      })
    },
    async CREATE_REPORT_REWARD_PUNISHMENT(
      { commit, dispatch, state },
      { idGuru, data },
    ) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/report_reward_punishment/create`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
          },
          data: {
            id_general_report: data.id_general_report,
            id_reward_punishment: data.id_reward_punishment,
            frequency: data.frequency,
            note: data.note,
          },
        })
        return new Promise((resolve, reject) => {
          if (resolve) {
            isSuccess = true
          }
          resolve(isSuccess)
        })
      } catch (error) {
        console.log('error', error)
      }
    },
    async DELETE_REPORT_REWARD_PUNISHMENT({ commit, dispatch, state }, { id }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/report_reward_punishment/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
          .then((data) => {
            status = data.status
          })
          .catch((err) => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },

    async FETCH_HEADER_PERSONAL_DATA(
      { commit, dispatch, rootState },
      { idGuru, isCurriculum },
    ) {
      commit(
        'SET_STATE',
        {
          isLoading: true,
        },
        { root: true },
      )
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/guru/overall-header`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            isCurriculum,
          },
        })

        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )

        console.log(err)
      }
    },

    async FETCH_LIST_PERSONAL_DATA(
      { commit, dispatch, rootState },
      { idKelas },
    ) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/kelas_tahun_ajaran/personal_data/${idKelas}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_ALL_PERSONAL_DATA({ commit, dispatch, rootState }, { data }) {
      let isSuccess = false
      try {
        await axios(
          `${config.apiUrl}/api/kelas_tahun_ajaran/personal_data/update_all`,
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
            data: {
              data,
            },
          },
        )
        isSuccess = true
        return new Promise((resolve) => {
          resolve(isSuccess)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve) => {
          resolve(isSuccess)
        })
      }
    },
    async SEND_REPORT_PARENT_EMAIL(
      { commit, dispatch, rootState },
      { pdfFile, idGeneralReport, idGuru },
    ) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/general_report/send_report_email`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
          },
          data: {
            pdf_file: pdfFile,
            id_general_report: idGeneralReport,
          },
        })
        isSuccess = true
        return new Promise((resolve) => {
          resolve(isSuccess)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve) => {
          resolve(isSuccess)
        })
      }
    },
    async VERIFY_REPORT(
      { commit, dispatch, rootState },
      { id, by, generalReport },
    ) {
      try {
        await axios(`${config.apiUrl}/api/general_report/verify/${id}`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          params: {
            by,
          },
          data: {
            general_report: generalReport,
          },
        })
        return new Promise((resolve) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_HOMEROOM_LEDGER(
      { commit, dispatch, state },
      { idGuru, reportType, idMataPelajaran, gradeTypeReport },
    ) {
      try {
        if (idMataPelajaran === 'All') {
          idMataPelajaran = ''
        }
        const { data } = await axios(
          `${config.apiUrl}/api/general_report/ledger`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_guru: idGuru,
              type_report: reportType,
              id_mata_pelajaran: idMataPelajaran,
              grade_type_report: gradeTypeReport,
            },
          },
        )
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data)
          }, 1000)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_ALL_STUDENT_ACHIEVEMENTS(
      { commit, dispatch, rootState },
      { page, order, sortBy, search, type, idGeneralReport },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/penghargaan-murid/report/${idGeneralReport}`,
          {
            method: 'get',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            params: {
              page,
              order,
              sortBy,
              search,
              type,
            },
          },
        )
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data.data)
          }, 500)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async CREATE_ACHIEVEMENT_BY_ID_GENERAL_REPORT(
      { commit, dispatch, rootState },
      { newAchievement, idGeneralReport },
    ) {
      try {
        await axios(
          `${config.apiUrl}/api/penghargaan-murid/report/${idGeneralReport}`,
          {
            method: 'post',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            data: {
              achievement: newAchievement,
            },
          },
        )
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true)
          }, 500)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async DELETE_ACHIEVEMENT({ commit, dispatch, rootState }, { id }) {
      try {
        await axios(`${config.apiUrl}/api/penghargaan-murid/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true)
          }, 500)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async UPDATE_ACHIEVEMENT(
      { commit, dispatch, rootState },
      { id, newAchievement },
    ) {
      try {
        await axios(`${config.apiUrl}/api/penghargaan-murid/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            achievement: newAchievement,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true)
          }, 500)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_ALL_HOMEROOM_STUDENT_CLASS_8355(
      { commit, dispatch, rootState },
      { idKelas },
    ) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/guru/homeroom/${idKelas}`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_LIST_STUDENTS_CLASS(
      { commit, dispatch, rootState },
      { idKelas },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/kelas/list-student-class`,
          {
            method: 'get',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            params: {
              idKelas,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async FETCH_STUDENT_PERIODIC_REPORT_PREVIEW(
      { commit, dispatch, rootState },
      { paramsMurid, selectedDays },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/murid/periodic-report`,
          {
            method: 'get',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            params: {
              selectedDays,
              idKTA: paramsMurid,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async SEND_PERIODIC_REPORT(
      { commit, dispatch, rootState },
      { idKelas, formData },
    ) {
      try {
        await axios(`${config.apiUrl}/api/murid/all-periodic-report`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            idKelas,
            selectedDays: formData.selectedDates,
            reportType: formData.reportType,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async FETCH_REWARD_PUNISHMENT_BY_GENERAL_REPORT(_, { idGeneralReport }) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/general_report/reward-punishment/${idGeneralReport}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_ACTIVITIES_BY_GENERAL_REPORT(_, { idGeneralReport }) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/general_report/activities/${idGeneralReport}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async UPDATE_REPORT_ACTIVITIES({ commit, dispatch, state }, { data }) {
      let isSuccess = false
      let dataTable
      console.log('data', data)
      await axios(`${config.apiUrl}/api/report_activities/update_all`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: { data: data },
      })
        .then((res) => {
          // console.log('res UPDATE_REPORT_REWARD_PUNISMENT', res)
          isSuccess = true
          dataTable = res.data.data
        })
        .catch((err) => {
          isSuccess = false
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            isSuccess,
            dataTable,
          })
        }, 2000)
      })
    },
    async CREATE_REPORT_ACTIVITIES({ commit, dispatch, state }, { data }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/report_activities/create`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: { data: data },
        })
        return new Promise((resolve, reject) => {
          if (resolve) {
            isSuccess = true
          }
          resolve(isSuccess)
        })
      } catch (error) {
        console.log('error', error)
      }
    },
    async DELETE_REPORT_ACTIVITIES({ commit, dispatch, state }, { id }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/report_activities/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
          .then((data) => {
            status = data.status
          })
          .catch((err) => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },

    async FETCH_CURRICULUM_HEADER_PERSONAL_DATA(
      { commit, dispatch, rootState },
      { idKelas, isCurriculum },
    ) {
      commit(
        'SET_STATE',
        {
          isLoading: true,
        },
        { root: true },
      )
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/guru/curriculum/overall-header`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_kelas: idKelas,
            isCurriculum,
          },
        })

        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )

        console.log(err)
      }
    },

    async FETCH_CURRICULUM_STUDENT_REPORT(_, { idKelas, type }) {
      try {
        const {
          data: { arrayMetadata, isMetadata, message, description },
        } = await axios(`${config.apiUrl}/api/general_report/metadata/`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_kelas: idKelas,
            type,
          },
        })
        return new Promise((resolve, reject) =>
          resolve({ arrayMetadata, isMetadata, message, description }),
        )
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
}
