export default {
  // apiUrl: 'https://demo.hasanteknologi.id:3000',
  // apiUrl: 'https://trial.hasanteknologi.id:3010',
  // apiUrl: 'https://trial.hasanteknologi.id:3011',
  // apiUrl: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  // apiInterconUrl: process.env.VUE_APP_INTERCON_API_URL || 'http://localhost:3013',
  apiInterconUrl:
    process.env.VUE_APP_INTERCON_API_URL ||
    'https://demo.hasanteknologi.id:3013',
  // apiUrl: 'http://localhost:3000',
  // apiUrl: 'http://localhost:3010',
  apiUrl: 'https://edutech.albayan.sch.id:3010',
  // apiUrl: 'https://api-elearning.citracendekia.sch.id',
  school: {
    // name: 'Edutech Albayan Islamic School',
    // name2: 'SMP Albayan Islamic School',
    name: 'Hasan Educational Technology',
    name2: 'Hasan Educational Technology School',
    logo: 'Logo-Hasan.png',
    // logo: 'Logo-Albayan-Islamic-School.png',
    logoCompress: 'logo_2.jpeg',
    type: 'SMP',
    religion: 'Islam',
    address: 'Jl. Basoka Raya, Kavling Hankam Blok R No. 6 Joglo,',
    city: ' Jakarta Barat, DKI Jakarta',
    number: 'Telp. (021) 584 0929 Fax. (021) 584 0929 584 8854',
  },
  uploader: {
    MAX_SIZE: 5000000,
  },
  templateVariables: [
    {
      name: 'Student Name',
      code: '#STUDENT_NAME# ',
    },
    {
      name: 'Template Type',
      code: '#TEMPLATE_TYPE# ',
    },
    {
      name: 'Alias',
      code: '#ALIAS# ',
    },
  ],
}
