<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import config from '@/config'
import setPrimaryColor from '@/helpers/setPrimaryColor'
// import { injectMathJax } from '@/helpers/mathjax.js'
export default {
  async created() {
    try {
      // injectMathJax()
      const version = localStorage.version
      if (!localStorage.institusi || process.env.VUE_APP_VERSION !== version) {
        if (process.env.VUE_APP_VERSION !== version) {
          localStorage.clear()
          localStorage.setItem('version', process.env.VUE_APP_VERSION)
          window.location.replace(window.location.href)
        } else localStorage.setItem('version', process.env.VUE_APP_VERSION)
        await this.$store.dispatch('master/FETCH_INSTITUSI')
      } else {
        const institusi = JSON.parse(localStorage.institusi)
        setPrimaryColor(institusi.primary_color)
      }

      if (localStorage.authorized && localStorage.user) {
        this.$store.dispatch('user/GET_USER', { user: JSON.parse(localStorage.user), role: JSON.parse(localStorage.role) })
      }

      // if (localStorage.cbtType) {
      //   const { cbtType } = localStorage
      //   this.$store.commit('SET_STATE', {
      //     cbtType,
      //   })
      // }
    } catch (err) {
      console.log(err)
    }
  },
}
</script>

<style>
</style>
