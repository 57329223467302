import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
import errorHandler from '@/helpers/errorHandler'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_ALL_ANNOUNCEMENT(
      { commit },
      { type, page, judul, limit, idLevel, idKelas, role },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/pengumuman/${role.toLowerCase()}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            type,
            page,
            judul,
            limit,
            id_level: idLevel,
            id_kelas: idKelas,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async FETCH_ANNOUNCEMENT_BY_ID(
      { commit },
      { id },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/pengumuman/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
      }
    },
    async DELETE_ANNOUNCEMENT({ commit, dispatch, state }, { idAnnouncement }) {
      try {
        await axios(`${config.apiUrl}/api/pengumuman/${idAnnouncement}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_STATUS_READ(_, payload) {
      try {
        await axios(`${config.apiUrl}/api/pengumuman/student/read/${payload.id_announcement}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
}
