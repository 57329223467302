import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_CURRICULUM_ALL_MAPEL_KBM(
      { commit, dispatch, state }, { selectedYear, filterBy, filterValue },
    ) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/kbm/mapel`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            selectedYear,
            filterBy,
            filterValue,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data)
          }, 500)
        })
      } catch (error) {
        console.log('error', error)
      }
    },
    async FETCH_ALL_MAPEL_KBM(
      { commit, dispatch, state },
    ) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/mapel`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data)
          }, 500)
        })
      } catch (error) {
        console.log('error', error)
      }
    },
    async FETCH_CLASS(
      { commit, dispatch, state }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/kelas`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        const resData = {
          classes: data.data,
          levels: data.dataTingkat,
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(resData)
          }, 500)
        })
      } catch (error) {
        console.log('error', error)
      }
    },
    async FETCH_TAHUN_AJARAN() {
      try {
        const { data } = await axios(`${config.apiUrl}/api/tahun_ajaran`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        // console.log(data)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async GENERATE_KBM({ commit, dispatch, state }, { id }) {
      let isSuccess = false
      await axios(`${config.apiUrl}/api/kbm/generate/${id}`, {
        method: 'post',
        headers: {
          token: localStorage.token,
        },
      })
      isSuccess = true
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ isSuccess })
        }, 2000)
      })
    },
    async UPDATE_KBM({ commit, dispatch, state }, payload) {
      let isSuccess = false
      let dataTable
      // console.log('payload', payload)
      await axios(`${config.apiUrl}/api/kbm/update_all`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          data: payload.data,
        },
      })
        .then(res => {
          isSuccess = true
        })
        .catch(err => {
          isSuccess = false
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            isSuccess,
            dataTable,
          })
        }, 2000)
      })
    },
    async POST_REPORT_SUBMISSION({ commit, dispatch, state }, payload) {
      let isSuccess = false
      // console.log('payload', payload)
      try {
        await axios(`${config.apiUrl}/api/report_setting`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...payload,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_REPORT_SUBMISSION({ commit, dispatch, state }, payload) {
      let isSuccess = false
      console.log('payload', payload)
      try {
        await axios(`${config.apiUrl}/api/report_setting/` + payload.id, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...payload.data,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_REPORT_SETTING({ commit, dispatch, state }, { idReportSetting }) {
      let isSuccess = false
      // console.log('payload', payload)
      try {
        await axios(`${config.apiUrl}/api/report_setting/` + idReportSetting, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_STUDENT_PARENT_INPUT_DATA_PROGRESS({ commit, dispatch, rootState }, { idKelas }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/guru/curriculum/class-sp-progress`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_kelas: idKelas,
          },
        })
        const result = {
          data: data.data,
          message: data.message,
          percentage: data.percentage || '-',
        }
        return new Promise((resolve) => {
          // console.log(result)
          resolve(result)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_HOMEROOM_TEACHER_PROGRESS(
      { commit, dispatch, state },
      { type },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/general_report/kurikulum`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              type,
            },
          },
        )
        const dataTable = data.data

        return new Promise((resolve, reject) => {
          resolve(dataTable)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_SUBJECT_TEACHERS_PROGRESS({ commit, dispatch, rootState }, { page, type, idGuru }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/general_report/kurikulum/mapel`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            type,
            id_guru: idGuru,
          },
        })
        return new Promise((resolve) => {
          // console.log(result)
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_DATA_PROGRESS_RPP({ commit, dispatch, rootState }, { select, page }) {
      try {
        const { data: { data, totalWeek, total } } = await axios(`${config.apiUrl}/api/rpp/planning-control`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            select,
            page,
          },
        })
        // const data = []
        return new Promise((resolve) => {
          // console.log(result)
          resolve({ data, totalWeek, total })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_DETAIL_RPP_BY_ID({ commit, dispatch, rootState }, { id }) {
      try {
        const { data: { data, weeks } } = await axios(`${config.apiUrl}/api/rpp/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        // const data = []
        return new Promise((resolve) => {
          // console.log(result)
          resolve({ data, weeks })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_GRADUATION_LETTER_BY_LEVEL_AND_ID_TAHUN_AJARAN({ commit, dispatch, rootState }, { idTahunAjaran }) {
      try {
        const { data: { data = [] } } = await axios(`${config.apiUrl}/api/kelas/last-level/by-year`, {
          method: 'get',
          params: { id_tahun_ajaran: idTahunAjaran },
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve) => {
          // console.log(result)
          resolve({ data })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_GRADUATION_LETTER_BY_CLASS({ commit, dispatch, rootState }, { idKelas }) {
      try {
        const { data: { data = [] } } = await axios(`${config.apiUrl}/api/skl/detail/${idKelas}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve) => {
          // console.log(result)
          resolve({ data })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_DAILY_ATTENDANCE({ commit, dispatch, rootState }) {
      try {
        const { data: { data = [] } } = await axios(`${config.apiUrl}/api/guru/curriculum/daily-attendance`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve) => {
          // console.log(result)
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
